<template>
  <div class="w-full px-6 sm:w-1/2 xl:w-1/3">
    <div
      class="flex items-center px-5 py-6 shadow-md rounded-md bg-white cursor-pointer hover:bg-gray-200"
    >
      <div class="p-3 rounded-full bg-yellow-600 bg-opacity-75">
        <svg
          class="h-6 w-6 text-white stroke-2"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="{2}"
            d="M10 9v6m4-6v6m7-3a9 9 0 11-18 0 9 9 0 0118 0z"
          />
        </svg>
      </div>

      <div class="mx-5">
        <h4 class="text-2xl font-semibold text-yellow-700">
          {{ loading ? '---' : count }}
        </h4>
        <div class="text-gray-500">Paused</div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    count: {
      type: Number,
      default: 0,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    return {}
  },
})
</script>

<style scoped></style>
