<template>
  <div class="w-full px-6 sm:w-1/2 xl:w-1/3">
    <div
      class="flex items-center px-5 py-6 shadow-md rounded-md bg-white cursor-pointer hover:bg-gray-200"
    >
      <div class="p-3 rounded-full bg-brand bg-opacity-75">
        <svg
          class="h-6 w-6 text-white"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path d="M8 2a1 1 0 000 2h2a1 1 0 100-2H8z" />
          <path
            d="M3 5a2 2 0 012-2 3 3 0 003 3h2a3 3 0 003-3 2 2 0 012 2v6h-4.586l1.293-1.293a1 1 0 00-1.414-1.414l-3 3a1 1 0 000 1.414l3 3a1 1 0 001.414-1.414L10.414 13H15v3a2 2 0 01-2 2H5a2 2 0 01-2-2V5zM15 11h2a1 1 0 110 2h-2v-2z"
          />
        </svg>
      </div>

      <div class="mx-5">
        <h4 class="text-2xl font-semibold text-gray-700">
          {{ loading ? '---' : count }}
        </h4>
        <div class="text-gray-500">Pending TaxNitro Review</div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    count: {
      type: Number,
      default: 0,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    return {}
  },
})
</script>

<style scoped></style>
