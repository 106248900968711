<template>
  <div v-if="errorMessage" class="text-red-500">
    {{ errorMessage }}
  </div>
  <div v-else class="max-w-6xl mx-auto py-6 px-3 sm:px-6 lg:px-8">
    <h5 class="text-xl font-semibold mt-4">Welcome {{ userName }},</h5>
    <companies-status class="mt-4" />
    <hr class="my-6" />
    <div v-if="role === 'admin'">
      <h3 class="text-lg text-gray-700 ml-1 mt-5">
        Failure Logs
      </h3>
      <button
        class="focus:outline-none float-right text-sm -mt-6 mr-4 text-gray-600 hover:text-red-500"
        @click="clearFailureLogs"
      >
        {{ logsClearing ? 'Deleting ...' : 'Clear logs' }}
      </button>
      <failure-list
        class="mt-4"
        :logs="recentFailures"
        @more="showMoreFailureLogs"
      />
    </div>
    <!-- <p class="mt-8">
      You have
      <span class="font-bold">
        {{ pendingEnrolmentReviewCount }}
      </span>
      enrolment request
      <span class="font-bold">
        pending
      </span>
      review.
    </p>
    <button
      class="mt-6 relative flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-brand hover:bg-brand-dark focus:outline-none transition duration-150 ease-in-out disabled:opacity-50 disabled:text-gray-500"
      @click="$router.push({ name: RouteName.ENROLMENT })"
    >
      View Enrollment List
    </button> -->
  </div>
</template>

<script lang="ts">
import { FirestoreColNames } from '@/enums/FirestoreColNames'
import { userData, db, functions } from '@/firebase'
import { defineComponent, onUnmounted, reactive, toRefs } from 'vue'
import { RouteName } from '../router/RouteName'
import CompaniesStatus from '@/components/dashboard/CompaniesStatus.vue'
import { FailureLogFirestoreDoc } from 'types/failure-log'
import FailureList from '@/components/dashboard/FailureList.vue'
import { FunctionNames } from '@/enums/FunctionNames'

export default defineComponent({
  components: { CompaniesStatus, FailureList },
  setup() {
    const state = reactive({
      RouteName: RouteName,
      errorMessage: '',
      pendingRightWayReview: 0,
      pendingTaxNitroReview: 0,
      requestRejected: 0,
      requestDeleted: 0,
      companiesRunning: 0,
      companiesPaused: 0,
      totalRequestReceived: 0,
      recentFailures: [] as FailureLogFirestoreDoc[],
      loading: true,
      logsClearing: false,
    })

    // const pendingEnrolmentReviewCount = ref(0)

    const userName = userData?.name

    const role = userData?.claims?.role

    let failureLogsListener: () => void

    if (role === 'admin') {
      failureLogsListener = db
        .collection(FirestoreColNames.FAILURE_LOGS)
        .orderBy('createdAt', 'desc')
        .limit(5)
        .onSnapshot(snap => {
          state.recentFailures = []
          for (const doc of snap.docs) {
            const data = doc.data() as FailureLogFirestoreDoc
            state.recentFailures.push(data)
          }
        })
    }

    const showMoreFailureLogs = () => {
      failureLogsListener = db
        .collection(FirestoreColNames.FAILURE_LOGS)
        .orderBy('createdAt', 'desc')
        .onSnapshot(snap => {
          state.recentFailures = []
          for (const doc of snap.docs) {
            const data = doc.data() as FailureLogFirestoreDoc
            state.recentFailures.push(data)
          }
        })
    }

    const clearFailureLogs = async () => {
      state.logsClearing = true
      await functions.httpsCallable(FunctionNames.CLEAR_FAILURE_LOGS)()
      state.logsClearing = false
    }

    onUnmounted(() => {
      if (failureLogsListener) {
        failureLogsListener()
      }
    })

    return {
      ...toRefs(state),
      userName,
      role,
      showMoreFailureLogs,
      clearFailureLogs,
    }
  },
})
</script>

<style></style>
