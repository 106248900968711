
import { FirestoreColNames } from '@/enums/FirestoreColNames'
import { userData, db, functions } from '@/firebase'
import { defineComponent, onUnmounted, reactive, toRefs } from 'vue'
import { RouteName } from '../router/RouteName'
import CompaniesStatus from '@/components/dashboard/CompaniesStatus.vue'
import { FailureLogFirestoreDoc } from 'types/failure-log'
import FailureList from '@/components/dashboard/FailureList.vue'
import { FunctionNames } from '@/enums/FunctionNames'

export default defineComponent({
  components: { CompaniesStatus, FailureList },
  setup() {
    const state = reactive({
      RouteName: RouteName,
      errorMessage: '',
      pendingRightWayReview: 0,
      pendingTaxNitroReview: 0,
      requestRejected: 0,
      requestDeleted: 0,
      companiesRunning: 0,
      companiesPaused: 0,
      totalRequestReceived: 0,
      recentFailures: [] as FailureLogFirestoreDoc[],
      loading: true,
      logsClearing: false,
    })

    // const pendingEnrolmentReviewCount = ref(0)

    const userName = userData?.name

    const role = userData?.claims?.role

    let failureLogsListener: () => void

    if (role === 'admin') {
      failureLogsListener = db
        .collection(FirestoreColNames.FAILURE_LOGS)
        .orderBy('createdAt', 'desc')
        .limit(5)
        .onSnapshot(snap => {
          state.recentFailures = []
          for (const doc of snap.docs) {
            const data = doc.data() as FailureLogFirestoreDoc
            state.recentFailures.push(data)
          }
        })
    }

    const showMoreFailureLogs = () => {
      failureLogsListener = db
        .collection(FirestoreColNames.FAILURE_LOGS)
        .orderBy('createdAt', 'desc')
        .onSnapshot(snap => {
          state.recentFailures = []
          for (const doc of snap.docs) {
            const data = doc.data() as FailureLogFirestoreDoc
            state.recentFailures.push(data)
          }
        })
    }

    const clearFailureLogs = async () => {
      state.logsClearing = true
      await functions.httpsCallable(FunctionNames.CLEAR_FAILURE_LOGS)()
      state.logsClearing = false
    }

    onUnmounted(() => {
      if (failureLogsListener) {
        failureLogsListener()
      }
    })

    return {
      ...toRefs(state),
      userName,
      role,
      showMoreFailureLogs,
      clearFailureLogs,
    }
  },
})
