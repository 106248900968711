import { db } from '@/firebase'
import { computed, reactive, toRefs } from 'vue'
import { FirestoreColNames } from '@/enums/FirestoreColNames'
import { SyncLogFirestoreDoc } from 'types/sync-log'
import useEnrolmentRequests from './useEnrolmentRequests'
import { firestore } from 'firebase'
import useEnrolledCompanies from './useEnrolledCompanies'

const state = reactive({
  lastSyncTime: '',
  lastSyncMethod: '',
})

const {
  loading: isEnrolRequestLoading,
  error: enrolmentRequestError,
  enrolmentRequests,
} = useEnrolmentRequests()

db.collection(FirestoreColNames.SYNC_LOGS)
  .orderBy('logAt', 'desc')
  .limit(1)
  .onSnapshot(snap => {
    if (!snap || !snap.docs) return
    if (!snap.docs.length) return
    const data = snap.docs[0].data() as SyncLogFirestoreDoc
    const timestamp = data.logAt as firestore.Timestamp
    state.lastSyncTime = timestamp.toDate().toLocaleString()
    state.lastSyncMethod = data.method
  })

const pendingRightWayReview = computed(() => {
  const filtered = enrolmentRequests?.value.filter(
    enrolment =>
      !enrolment.deleted &&
      !enrolment.rejected &&
      enrolment.taxNitroReviewed &&
      !enrolment.rightWayReviewed
  )
  return filtered?.length
})

const pendingTaxNitroReview = computed(() => {
  const filtered = enrolmentRequests?.value.filter(
    enrolment =>
      !enrolment.deleted && !enrolment.rejected && !enrolment.taxNitroReviewed
  )
  return filtered?.length
})

const requestRejected = computed(() => {
  const filtered = enrolmentRequests?.value.filter(
    enrolment => !enrolment.deleted && enrolment.rejected
  )
  return filtered?.length
})

const requestDeleted = computed(() => {
  const filtered = enrolmentRequests?.value.filter(
    enrolment => enrolment.deleted
  )
  return filtered?.length
})

const totalRequestReceived = computed(() => enrolmentRequests?.value.length)

const {
  loading: isCompaniesLoading,
  error: enrolledCompaniesError,
  enrolledCompanies,
} = useEnrolledCompanies()

const summaryError = computed(
  () => enrolmentRequestError.value || enrolledCompaniesError.value
)

const companiesRunning = computed(() => {
  const filtered = enrolledCompanies.value.filter(
    company => !company.paused && !company.deleted
  )

  return filtered.length
})
const companiesPaused = computed(() => {
  const filtered = enrolledCompanies.value.filter(
    company => company.paused && !company.deleted
  )

  return filtered.length
})

export default function() {
  return {
    isEnrolRequestLoading,
    isCompaniesLoading,
    pendingTaxNitroReview,
    pendingRightWayReview,
    requestRejected,
    requestDeleted,
    totalRequestReceived,
    summaryError,
    companiesPaused,
    companiesRunning,
    ...toRefs(state),
  }
}
