<template>
  <div>
    <div v-if="errorMessage" class="text-red-500">
      There's an error fetching summaries. {{ errorMessage }}
    </div>
    <div v-else>
      <hr />
      <h3 class="text-lg text-gray-700 ml-1 mt-5">Enrollment Request</h3>
      <div class="flex flex-wrap -mx-6">
        <pending-tax-nitro
          class="my-3"
          :count="pendingTaxNitroReview"
          :loading="isEnrolRequestLoading"
          @click="goToEnrolments(['pendingTaxNitro'])"
        />
        <pending-right-way
          class="my-3"
          :count="pendingRightWayReview"
          :loading="isEnrolRequestLoading"
          @click="goToEnrolments(['pendingRightWay'])"
        />
        <request-rejected
          class="my-3"
          :count="requestRejected"
          :loading="isEnrolRequestLoading"
          @click="goToEnrolments(['rejected'])"
        />
        <request-total
          class="my-3"
          :count="totalRequestReceived"
          :loading="isEnrolRequestLoading"
          @click="goToEnrolments([])"
        />
      </div>
      <hr class="my-6" />
      <button
        :disabled="isTriggeringClearCaches"
        v-show="role === 'admin'"
        class="float-right relative ml-4 text-xs py-2 px-3 border leading-5 font-medium rounded-md text-gray-600 bg-white hover:bg-gray-200 focus:outline-none transition duration-150 ease-in-out disabled:opacity-50 disabled:text-gray-500"
        @click="clearCaches"
      >
        {{ isTriggeringClearCaches ? 'Clearing ...' : 'Clear Caches' }}
      </button>

      <button
        :disabled="isTriggeringSync"
        v-show="role === 'admin'"
        class="float-right relative py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-brand hover:bg-brand-dark focus:outline-none transition duration-150 ease-in-out disabled:opacity-50 disabled:text-gray-500"
        @click="forceSync"
      >
        {{ isTriggeringSync ? 'Triggering ...' : 'Force Sync' }}
      </button>
      <h3 class="text-lg text-gray-700 ml-1 mt-5">Synchronization Status</h3>
      <p class="text-xs text-gray-500 ml-2">
        Last sync: On {{ lastSyncTime }} by {{ lastSyncMethod }}
      </p>
      <div class="flex flex-wrap -mx-6 mt-6">
        <companies-running
          class="my-3"
          :count="companiesRunning"
          :loading="isCompaniesLoading"
          @click="goToCompanies(['running'])"
        />
        <companies-paused
          class="my-3"
          :count="companiesPaused"
          :loading="isCompaniesLoading"
          @click="goToCompanies(['paused'])"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { userData, functions } from '@/firebase'
import { RouteName } from '@/router/RouteName'
import { defineComponent, reactive, toRefs } from 'vue'
import PendingTaxNitro from './numbers/PendingTaxNitro.vue'
import PendingRightWay from './numbers/PendingRightWay.vue'
import RequestRejected from './numbers/RequestRejected.vue'
import RequestTotal from './numbers/RequestTotal.vue'
import CompaniesRunning from './numbers/CompaniesRunning.vue'
import CompaniesPaused from './numbers/CompaniesPaused.vue'
import { FunctionNames } from '@/enums/FunctionNames'
import useSummaries from '@/composables/useSummaries'
import { useRouter } from 'vue-router'
import useEnrolmentRequests, {
  EnrolmentStatus,
} from '@/composables/useEnrolmentRequests'
import useEnrolledCompanies, {
  CompanyStatus,
} from '@/composables/useEnrolledCompanies'

export default defineComponent({
  components: {
    PendingRightWay,
    PendingTaxNitro,
    RequestRejected,
    RequestTotal,
    CompaniesRunning,
    CompaniesPaused,
  },
  setup() {
    const state = reactive({
      RouteName: RouteName,
      isTriggeringSync: false,
      isTriggeringClearCaches: false,
    })

    const {
      summaryError: errorMessage,
      pendingRightWayReview,
      pendingTaxNitroReview,
      requestRejected,
      requestDeleted,
      totalRequestReceived,
      isEnrolRequestLoading,
      isCompaniesLoading,
      companiesRunning,
      companiesPaused,
      lastSyncTime,
      lastSyncMethod,
    } = useSummaries()

    const { currentFilters: currentEnrolmentFilters } = useEnrolmentRequests()
    const { currentFilters: currentCompanyFilters } = useEnrolledCompanies()

    const forceSync = async () => {
      state.isTriggeringSync = true
      await functions.httpsCallable(FunctionNames.MANUAL_FORCE_SYNC)()
      state.isTriggeringSync = false
    }

    const clearCaches = async () => {
      state.isTriggeringClearCaches = true
      await functions.httpsCallable(FunctionNames.ADMIN_CLEAR_CACHES)()
      state.isTriggeringClearCaches = false
    }

    const router = useRouter()

    const goToEnrolments = (filters: EnrolmentStatus[]) => {
      currentEnrolmentFilters.value = filters
      router.push({
        name: RouteName.ENROLMENT,
      })
    }
    const goToCompanies = (filters: CompanyStatus[]) => {
      currentCompanyFilters.value = filters
      router.push({
        name: RouteName.ENROLLED_COMPANIES,
      })
    }

    const role = userData?.claims?.role
    return {
      ...toRefs(state),
      role,
      forceSync,
      clearCaches,
      errorMessage,
      pendingRightWayReview,
      pendingTaxNitroReview,
      requestRejected,
      requestDeleted,
      totalRequestReceived,
      isEnrolRequestLoading,
      isCompaniesLoading,
      companiesRunning,
      companiesPaused,
      lastSyncTime,
      lastSyncMethod,
      goToEnrolments,
      goToCompanies,
    }
  },
})
</script>

<style scoped></style>
