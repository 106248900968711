<template>
  <div v-if="logs.length">
    <p class="text-sm ml-2 text-gray-500">Showing top 5 recent logs</p>
    <ol class="list-decimal ml-5 mt-4 text-red-600 text-xs">
      <li v-for="log in logs" :key="log.createdAt">{{ log.message }}</li>
    </ol>
    <a
      class="ml-2 text-gray-500 text-sm cursor-pointer hover:text-gray-700"
      @click.prevent="$emit('more')"
      >more ...</a
    >
  </div>
  <div v-else>
    <p class="text-sm ml-2 text-green-500">No error has been recorded.</p>
  </div>
</template>

<script lang="ts">
import { FailureLogFirestoreDoc } from 'types/failure-log'
import { defineComponent, PropType } from 'vue'

export default defineComponent({
  props: {
    logs: {
      type: Object as PropType<FailureLogFirestoreDoc[]>,
      required: true,
    },
  },
  emits: ['more'],
  setup() {
    return {}
  },
})
</script>

<style scoped></style>
