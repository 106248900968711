<template>
  <div class="w-full px-6 sm:w-1/2 xl:w-1/3">
    <div
      class="flex items-center px-5 py-6 shadow-md rounded-md bg-white cursor-pointer hover:bg-gray-200"
    >
      <div class="p-3 rounded-full bg-red-600 bg-opacity-75">
        <svg
          class="h-6 w-6 text-white"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fillRule="evenodd"
            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
            clipRule="evenodd"
          />
        </svg>
      </div>

      <div class="mx-5">
        <h4 class="text-2xl font-semibold text-gray-700">
          {{ loading ? '---' : count }}
        </h4>
        <div class="text-gray-500">Request Rejected</div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    count: {
      type: Number,
      default: 0,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    return {}
  },
})
</script>

<style scoped></style>
