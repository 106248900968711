
import { FailureLogFirestoreDoc } from 'types/failure-log'
import { defineComponent, PropType } from 'vue'

export default defineComponent({
  props: {
    logs: {
      type: Object as PropType<FailureLogFirestoreDoc[]>,
      required: true,
    },
  },
  emits: ['more'],
  setup() {
    return {}
  },
})
