
import { userData, functions } from '@/firebase'
import { RouteName } from '@/router/RouteName'
import { defineComponent, reactive, toRefs } from 'vue'
import PendingTaxNitro from './numbers/PendingTaxNitro.vue'
import PendingRightWay from './numbers/PendingRightWay.vue'
import RequestRejected from './numbers/RequestRejected.vue'
import RequestTotal from './numbers/RequestTotal.vue'
import CompaniesRunning from './numbers/CompaniesRunning.vue'
import CompaniesPaused from './numbers/CompaniesPaused.vue'
import { FunctionNames } from '@/enums/FunctionNames'
import useSummaries from '@/composables/useSummaries'
import { useRouter } from 'vue-router'
import useEnrolmentRequests, {
  EnrolmentStatus,
} from '@/composables/useEnrolmentRequests'
import useEnrolledCompanies, {
  CompanyStatus,
} from '@/composables/useEnrolledCompanies'

export default defineComponent({
  components: {
    PendingRightWay,
    PendingTaxNitro,
    RequestRejected,
    RequestTotal,
    CompaniesRunning,
    CompaniesPaused,
  },
  setup() {
    const state = reactive({
      RouteName: RouteName,
      isTriggeringSync: false,
      isTriggeringClearCaches: false,
    })

    const {
      summaryError: errorMessage,
      pendingRightWayReview,
      pendingTaxNitroReview,
      requestRejected,
      requestDeleted,
      totalRequestReceived,
      isEnrolRequestLoading,
      isCompaniesLoading,
      companiesRunning,
      companiesPaused,
      lastSyncTime,
      lastSyncMethod,
    } = useSummaries()

    const { currentFilters: currentEnrolmentFilters } = useEnrolmentRequests()
    const { currentFilters: currentCompanyFilters } = useEnrolledCompanies()

    const forceSync = async () => {
      state.isTriggeringSync = true
      await functions.httpsCallable(FunctionNames.MANUAL_FORCE_SYNC)()
      state.isTriggeringSync = false
    }

    const clearCaches = async () => {
      state.isTriggeringClearCaches = true
      await functions.httpsCallable(FunctionNames.ADMIN_CLEAR_CACHES)()
      state.isTriggeringClearCaches = false
    }

    const router = useRouter()

    const goToEnrolments = (filters: EnrolmentStatus[]) => {
      currentEnrolmentFilters.value = filters
      router.push({
        name: RouteName.ENROLMENT,
      })
    }
    const goToCompanies = (filters: CompanyStatus[]) => {
      currentCompanyFilters.value = filters
      router.push({
        name: RouteName.ENROLLED_COMPANIES,
      })
    }

    const role = userData?.claims?.role
    return {
      ...toRefs(state),
      role,
      forceSync,
      clearCaches,
      errorMessage,
      pendingRightWayReview,
      pendingTaxNitroReview,
      requestRejected,
      requestDeleted,
      totalRequestReceived,
      isEnrolRequestLoading,
      isCompaniesLoading,
      companiesRunning,
      companiesPaused,
      lastSyncTime,
      lastSyncMethod,
      goToEnrolments,
      goToCompanies,
    }
  },
})
