<template>
  <div class="w-full px-6 sm:w-1/2 xl:w-1/3">
    <div
      class="flex items-center px-5 py-6 shadow-md rounded-md bg-white cursor-pointer hover:bg-gray-200"
    >
      <div class="p-3 rounded-full bg-green-700 bg-opacity-75">
        <svg
          class="h-6 w-6 text-white"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fillRule="evenodd"
            d="M2 6a2 2 0 012-2h4l2 2h4a2 2 0 012 2v1H8a3 3 0 00-3 3v1.5a1.5 1.5 0 01-3 0V6z"
            clipRule="evenodd"
          />
          <path
            d="M6 12a2 2 0 012-2h8a2 2 0 012 2v2a2 2 0 01-2 2H2h2a2 2 0 002-2v-2z"
          />
        </svg>
      </div>

      <div class="mx-5">
        <h4 class="text-2xl font-semibold text-gray-700">
          {{ loading ? '---' : count }}
        </h4>
        <div class="text-gray-500">Total Request Received</div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    count: {
      type: Number,
      default: 0,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    return {}
  },
})
</script>

<style scoped></style>
