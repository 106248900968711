import { FirestoreColNames } from '@/enums/FirestoreColNames'
import { db, userData } from '@/firebase'
import arraySort from 'array-sort'
import { EnrolledFirestoreDoc } from 'types/enrolment'
import { computed, ref, watch } from 'vue'

export type CompanyStatus = 'running' | 'paused' | 'deleted'

const role = userData?.claims?.role

const enrolledCompanies = ref<EnrolledFirestoreDoc[]>([])
const loading = ref(true)
const error = ref('')

const currentSearchKeyword = ref<string | null>(null)
const currentFilters = ref<CompanyStatus[]>(['running', 'paused'])
const currentOrderBy = ref({
  field: 'enrolledAt',
  direction: 'desc',
})
const pageSize = ref(8)

const filteredData = ref<EnrolledFirestoreDoc[]>([])
const totalData = computed(() => filteredData.value.length)
const dataOnPage = ref<EnrolledFirestoreDoc[]>([])
const currentPage = ref(1)
const totalPage = computed(() =>
  Math.ceil(filteredData.value.length / pageSize.value)
)

db.collection(FirestoreColNames.ENROLLED_COMPANIES).onSnapshot(function(
  snapshot
) {
  const receivedEnrolledCompanies: EnrolledFirestoreDoc[] = []

  snapshot.forEach(doc => {
    const data = doc.data() as EnrolledFirestoreDoc

    receivedEnrolledCompanies.push(data)
  })

  enrolledCompanies.value = receivedEnrolledCompanies
  loading.value = false
})

if (!role) {
  error.value = 'Unauthorized to view Enrollment Requests.'
}

const filterAndSortData = () => {
  loading.value = true
  currentPage.value = 1

  let result: EnrolledFirestoreDoc[] = enrolledCompanies.value

  if (currentSearchKeyword.value && currentSearchKeyword.value !== '') {
    const searchTerm = currentSearchKeyword.value
    result = result.filter(
      enrolment => enrolment.keywords && enrolment.keywords.includes(searchTerm)
    )
  }

  if (currentFilters.value && currentFilters.value.length) {
    const filters = currentFilters.value

    const filteredCompanies: EnrolledFirestoreDoc[] = []

    result.forEach(company => {
      // Skip for already in filteredCompanies
      if (filteredCompanies.find(el => el.locationID === company.locationID))
        return

      if (filters.includes('running') && !company.paused && !company.deleted) {
        filteredCompanies.push(company)
      }

      if (filters.includes('paused') && company.paused && !company.deleted) {
        filteredCompanies.push(company)
      }

      if (filters.includes('deleted') && company.deleted) {
        filteredCompanies.push(company)
      }
    })

    result = filteredCompanies
  }

  if (currentOrderBy.value) {
    const field = currentOrderBy.value.field
    const direction = currentOrderBy.value.direction

    result = arraySort(result, field, { reverse: direction === 'desc' })
  }

  loading.value = false
  return result
}

const paginateData = () => {
  dataOnPage.value = filteredData.value.slice(
    (currentPage.value - 1) * pageSize.value,
    currentPage.value * pageSize.value
  )
}

// Get the pre-tabulated cursor info from firestore

watch(
  [enrolledCompanies, currentSearchKeyword],
  () => {
    if (loading.value) return
    filteredData.value = filterAndSortData()
  },
  { immediate: false, deep: true }
)
watch(
  [currentFilters, currentOrderBy],
  () => {
    if (loading.value) return
    filteredData.value = filterAndSortData()
  },
  { immediate: true, deep: true }
)

watch(
  [filteredData, currentPage],
  () => {
    paginateData()
  },
  { immediate: false, deep: true }
)

const nextPage = () => {
  if (currentPage.value === totalPage.value) return
  currentPage.value++
}
const prevPage = () => {
  if (currentPage.value === 1) return
  currentPage.value--
}
export default function() {
  return {
    dataOnPage,
    loading,
    totalData,
    totalPage,
    nextPage,
    prevPage,
    currentPage,
    enrolledCompanies,
    pageSize,
    error,
    currentFilters,
    currentSearchKeyword,
    currentOrderBy,
  }
}
